import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "UI/Button";
import PageWrapper from "UI/PageWrapper";
import './Tasks.css'
import Icon, { IconType } from "UI/Icon";
import coinImg from 'assets/images/coin.png'
import heroImg from 'assets/images/hero.png'
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import Task from "types/task";
import getTasks from "api/get-tasks";
import doneTask from "api/done-task";
import tg from "utils/tg";
import dailyRewards, { ResponceDailyRewards } from "api/get-daily-rewards";
import { toast } from "react-toastify";
const text = {
  taskNotDone:'The conditions of the task are not completed'
}
const Tasks = () => {
  const {user,setUser} = useContext(appContext)
  const [tasks, settasks] = useState<Task[] | null>(null)
  const [daily, setdaily] = useState<ResponceDailyRewards | null>(null)
  const [selectedAll, setSelectedAll] = useState(false);
  const [pendingTasks, setPendingTasks] = useState<string[]>([]);

  const updateTasks = async () => {
    const result = await getTasks();
    if (result) {
      settasks(result.tasks.sort((TaskA, TaskB) => {
        if (TaskA.status === TaskB.status) {
          return 0;
        }
        if (TaskA.status === 2) {
          return -1;
        }
        if (TaskB.status === 2) {
          return 1;
        }
        return TaskA.status - TaskB.status; // Для сортировки 0 и 1
      }));
    }
    const dailyResult = await dailyRewards()
    if(dailyResult){
      setdaily(dailyResult)
      if(dailyResult.alert) toast(dailyResult.alert,{icon:(
        <img src={coinImg} width={24} height={24}/>
      )})
    }
  };

  const claimTask = (taskID:string,url:string) => {
    setPendingTasks([...pendingTasks,taskID])
    if (url) {
      try {
        tg.openTelegramLink(url)
      } catch (error) {
        tg.openLink(url)
      }
    }
    const HandleTask = async () => {
      setPendingTasks(pendingTasks.filter(id => id !== taskID))
        const result = await doneTask(taskID)
        const err = () => tg.showAlert('Something wrong')
        switch (result?.status) {
            case 2:
              if (!url) toast(text.taskNotDone,{autoClose:1000,hideProgressBar:true})
            break;
        }
        if(result?.balance && user) setUser({...user,balance:result?.balance})
        if(!result) err()
        updateTasks()
    } 

    setTimeout(HandleTask,3000)
  }

 
  useEffect(() => {
    updateTasks()
  }, [])


  return (
    <PageWrapper className={selectedAll ? 'blured-page' : ''}>
      <h1>Tasks</h1>

      <div className="task-category-select">
        <Button
          className={!selectedAll ? "active" : "pink-opacity"}
          onClick={() => setSelectedAll(false)}
        >
          Daily
        </Button>
        <Button
          className={selectedAll ? "active" : "pink-opacity"}
          onClick={() => setSelectedAll(true)}
        >
          All
        </Button>
      </div>
     <AnimatePresence>
        <СategoryWrapper if={!selectedAll}>
          <div className="chest-rewards pink-opacity">
              <Icon icon="ruby"/>
              <Icon icon="star"/>
              <Icon icon="ton"/>
              <img src={coinImg} alt="" />
            </div>
            <img src={heroImg} className="hero"/>
           
            <ul className="daily-rewards-list">
              {daily?.everyday.stages.map((reward,i) => (
                <li key={i} className={daily.everyday.current_stage === i ? 'current pink-opacity' : ''}>
                  {i+1} day 
                  <img src={coinImg} alt="" />
                + {formatNumber(reward)}
                </li>
              ))}
            </ul>
        </СategoryWrapper>
        <СategoryWrapper if={selectedAll}>
        <ul className="task-list">
          {tasks?.map(({id,reward,title,url,status}) => {
            const handleClick = () => {
              if(status === 0 || status === 2) claimTask(id,url)
            }
            const isPending = pendingTasks.findIndex(tID => tID === id) !== -1
            let icon:IconType = 'arrowRight'
            switch (true) {
              case isPending:
                icon = 'loading'
                break;
              
                case status === 1:
                  icon = 'done'
                  break;

                  case status === 2:
                    icon = 'timer'
                    break;

              default:
                break;
            }
            return (
              <motion.li layout className="task-card pink-opacity" key={id} onClick={handleClick}>
                <p>{title}</p>
                <div className="task-card-reward-info">
                <img src={coinImg} alt="" />
                + {formatNumber(reward)}
                <Icon icon={icon}/>
                </div>
              </motion.li>
            )
          })}
        </ul>
        </СategoryWrapper>
     </AnimatePresence>
    </PageWrapper>
  );
};
interface СategoryWrapperProps extends HTMLMotionProps<"div"> {
  if?:boolean
}
const СategoryWrapper = (props:СategoryWrapperProps) => {
  if(!props.if) return null
  return <motion.div {...props}
  initial={{opacity:0}}
  animate={{opacity:1}}
  exit={{opacity:0}}
  >
    {props.children}
  </motion.div>
}

export default Tasks;
