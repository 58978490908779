import React, { useContext, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import formatNumber from 'utils/format-number'
import coinImg from 'assets/images/coin.png'
import temp from 'assets/images/ubder-construction.png'
import './Main.css'
import appContext from 'utils/app-context'
import { motion } from 'framer-motion'
type Props = {}

const Main = (props: Props) => {
  const {user} = useContext(appContext)
  const [greeting, setgreeting] = useState<boolean>(false)

  const showGreeting = () => {
    setgreeting(true)
    setTimeout(() => {
        setgreeting(false)
    }, 2000);
  } 

  return (
    <PageWrapper className='main-page' onClick={showGreeting}>
      <div className="main-top-bar">
        <div className="main-top-bar-profile">
          <img src={user?.avatar} alt="" />
          <p>{user?.first_name}</p>
        </div>
        <div className="main-top-bar-activity">
          <p>{user?.days_in_game}</p>
          <span>days in game</span>
        </div>
        <div className="main-top-bar-balance">
          <img src={coinImg} alt="" />
          {formatNumber(user?.balance)}
        </div>
      </div>
      {greeting && <motion.div
            initial={{opacity:0}}
            animate={{opacity:1}}
            className="hero-greeting">
              Hi {user?.first_name}! <br />
              The main character will be available coming soon
            </motion.div>}
      <img src={temp} className='under-construction' />
    </PageWrapper>
  )
}

export default Main